
import { Vue, Component, Watch } from "vue-property-decorator";
import { informativeStore } from "@/store/typed";
import FileDownloader from "@/components/Common/FileDownloader.vue";
import InformativeContent from "@/models/strapi/InformativeContent";
import { safeAsync } from "@/utils/AsyncUtil";
import Spinner from "@/components/Common/Spinner.vue";

@Component({
  components: {
    FileDownloader,
    Spinner,
  },
})
export default class DocumentColumns extends Vue {
  async created() {
    const [contents, contentsError] = await safeAsync(
      informativeStore.loadContent()
    );
  }

  get infos() {
    return informativeStore.content;
  }

  get regDocs() {
    return this.infos?.regulation_docs;
  }

  get techDocs() {
    return this.infos?.tech_docs;
  }

  get opDocs() {
    return this.infos?.op_docs;
  }
}
