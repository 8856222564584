
import { Vue, Component, Prop } from "vue-property-decorator";

import VSvg from "@/components/Common/VSvg.vue";

export interface BadgeValue {
  id: string;
  value: string;
  selected: boolean;
  hover?: boolean;
}

@Component({
  components: {
    VSvg,
  },
})
export default class BadgeGroup extends Vue {
  @Prop({ required: true }) readonly values!: BadgeValue[];

  private badges: BadgeValue[] = [];

  created() {
    if (this.values) {
      this.badges = this.values.map((el: BadgeValue) => {
        return { ...el };
      });
    }
  }

  private selected(value: BadgeValue) {
    return value.selected;
  }

  private setSelected(index: number, value: boolean) {
    const newValue = { ...this.badges[index] };
    newValue.selected = value;
    newValue.hover = value;
    this.$set(this.badges, index, newValue);
    this.$emit("toggle-select", this.badges);
  }

  private setHover(index: number, value: boolean) {
    const newValue = { ...this.badges[index] };
    newValue.hover = value;
    this.$set(this.badges, index, newValue);
  }

  private isHovered(index: number) {
    return this.badges[index].hover;
  }
}
