
import { Component, Vue } from "vue-property-decorator";
import { zoneStore } from "@/store/typed";
import marked from "marked";
import { urlFromMedia } from "@/utils/UrlHelper";
import Media from "@/models/strapi/Media";
import { NoCache } from "@/utils/Decorators";
import Company from "@/models/strapi/Company";

@Component
export default class About extends Vue {
  @NoCache
  get companies(): Company[] {
    return zoneStore.zone?.companies;
  }

  get isMobile(): boolean {
    return this.$mq === "md" || this.$mq === "sm" || this.$mq === "xs";
  }

  getMdContent(content: string): string {
    return marked(content);
  }

  url(media?: Media): string {
    return urlFromMedia(media);
  }
}
