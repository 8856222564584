
import { Component, Vue, Prop } from "vue-property-decorator";
import { capitalize } from "@/utils/stringUtils";
import marked from "marked";
import Faq from "@/models/strapi/Faq";

@Component({})
export default class FaqItem extends Vue {
  @Prop({ required: true }) value!: Faq;

  private open = false;

  get zone() {
    return capitalize(this.$route.params["zone"].toString());
  }

  mdFormatted(text: string) {
    return marked(text);
  }

  toggleOpen() {
    this.open = !this.open;
  }
}
