
import { Component, Mixins, Vue, Watch } from "vue-property-decorator";
import { zoneStore } from "@/store/typed";
import { capitalize } from "@/utils/stringUtils";
import marked from "marked";
import FaqItem from "@/components/Faq/Item.vue";
import BadgeGroup, { BadgeValue } from "@/components/Common/BadgeGroup.vue";
import FaqDomain from "@/models/strapi/FaqDomain";
import Faq from "@/models/strapi/Faq";
import FaqsMixin from "@/mixins/http/strapi/FaqsMixin";
import { safeAsync } from "@/utils/AsyncUtil";

@Component({
  components: {
    FaqItem,
    BadgeGroup,
  },
})
export default class FAQ extends Mixins(FaqsMixin) {
  private faqs: Faq[] = [];
  private faqDomains: FaqDomain[] = [];

  async created() {
    this.faqs = zoneStore.zone?.faq;
    const [data, errors] = await safeAsync(this.getDomains({}));

    if (data) {
      this.faqDomains = data;
    }
  }

  @Watch("ready")
  onStoreReady() {
    this.faqs = zoneStore.zone?.faq;
  }

  get zone() {
    return capitalize(this.$route.params["zone"].toString());
  }

  get ready() {
    return zoneStore.zone && this.faqDomains.length;
  }

  get domainsToBadges(): BadgeValue[] {
    if (!this.faqDomains.length) {
      return [];
    }
    return this.faqDomains.map((el: FaqDomain) => {
      return { id: el.id, value: el.domain, selected: false };
    });
  }

  private filterFaq(badges: BadgeValue[]) {
    if (badges.every((el: BadgeValue) => el.selected === false)) {
      this.faqs = zoneStore.zone?.faq;
      return;
    }

    this.faqs = [];
    badges.forEach((el: BadgeValue) => {
      if (el.selected) {
        this.faqs = this.faqs.concat(
          zoneStore.zone.faq.filter((faq: Faq) => faq.faq_domain.id === el.id)
        );
      }
    });
  }
}
