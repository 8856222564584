
import { Vue, Component, Prop } from "vue-property-decorator";
import { urlFromMedia } from "@/utils/UrlHelper";
import MediaFile from "@/models/strapi/MediaFile";
import moment from "moment";

@Component({})
export default class FileDownloader extends Vue {
  @Prop() readonly document!: MediaFile;

  get url(): string {
    return urlFromMedia(this.document.file);
  }

  get updateDate(): string {
    return moment(this.document.last_update).format("DD MMM YYYY");
  }

  download() {
    window.open(this.url);
  }
}
