
import Vue from "vue";
import Component from "vue-class-component";
import DocumentColumns from "../../components/Info/DocumentColumns.vue";
import PageSection from "@/components/Common/PageSection.vue";

@Component({
  components: { DocumentColumns, PageSection },
})
export default class Documents extends Vue {}
