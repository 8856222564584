export function capitalize(value: string): string {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function camelToSnake(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
}

export function snakeToCamel(str: string): string {
  return str
    .replace(/[-_][a-z]/gi, (letter) => `${letter.toUpperCase()}`)
    .replace("_", "");
}
